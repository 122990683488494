.App {
  text-align: center;
  margin-top: 30px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.your-checked-class {
  outline: none;
}
.mantine-1cejh2o, .mantine-1vxgjv3 {
  width: 10px;
}
.__mantine-ref-iconWrapper.mantine-dpym4z.mantine-Chips-iconWrapper {
  width: 10px;
}

.mantine-e6hqpv.mantine-Chips-checkIcon {
  display: none !important;
  width: 0;
  height: 0;
}
.mantine-1hrjny.__mantine-ref-filled, .mantine-1hrjny.__mantine-ref-filled:hover {
  background-color: green;
  color: white;
  font-weight: 500;
}

.mantine-1wub3cy.__mantine-ref-filled, .mantine-1wub3cy.__mantine-ref-filled:hover {
  background-color: red;
  color: white;
  font-weight: 500
}